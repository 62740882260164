import React from 'react'
import { Link } from 'gatsby'
import moment from 'moment'
import './style.scss'

class Book extends React.Component {
  render() {
    const {
      title,
      date,
      lang,
      author,
      description,
    } = this.props.data.node.frontmatter
    const { slug, authorSlug } = this.props.data.node.fields

    return (
      <div className="post">        
        <p className="post__title">
          <span className="post__meta-lang">{lang}</span>
          <Link className="post__title-link" to={slug}>
            {title}
          </Link> 
          <span class="post__meta-author">{author}</span>
          <time
            className="post__meta-time"
            dateTime={moment(date).format('MMMM D, YYYY')}
          >
            {moment(date).format('MMMM YYYY')}
          </time>
            
          
          
        </p>        
      </div>
    )
  }
}

export default Book
